import React from "react"
import { Carousel, Image } from "react-bootstrap"

import img01_512 from "../assets/kran/kran01_512.jpg"
import img01_1024 from "../assets/kran/kran01_1024.jpg"

import img02_512 from "../assets/kran/kran02_512.jpg"
import img02_1024 from "../assets/kran/kran02_1024.jpg"

import img03_512 from "../assets/kran/kran03_512.jpg"
import img03_1024 from "../assets/kran/kran03_1024.jpg"

export default () => (
  <div>
    <Carousel
      className="text-center"
      indicators={true}
      pauseOnHover={false}
      interval={"3000"}
      fade
      fluid
    >
      <Carousel.Item>
        <Image
          fluid
          src={img01_1024}
          srcSet={`${img01_512} 512w, ${img01_1024} 1024w`}
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
          fluid
          src={img02_1024}
          srcSet={`${img02_512} 512w, ${img02_1024} 1024w`}
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
          fluid
          src={img03_1024}
          srcSet={`${img03_512} 512w, ${img03_1024} 1024w`}
          alt=""
        />
      </Carousel.Item>
    </Carousel>
  </div>
)
